var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ui-grid-layout',[_c('template',{slot:"h1"},[_vm._v("Suchvorschläge")]),_c('template',{slot:"h2"},[_vm._v("Hier finden Sie von der dpa-Redaktion erstellte Sammlungen von Tönen zu aktuellen und zeitlosen Themen. Die Inhalte werden fortlaufend aktualisiert.")]),_c('template',{slot:"gridContent"},[(_vm.curatedSearchesSorted.length && _vm.isUserView)?_vm._l((_vm.curatedSearchesSorted),function(ref,index){
var label = ref.label;
var src = ref.src;
var id = ref.id;
return _c('ui-image-card',{key:("curatedSearch-" + index),staticClass:"overflow-hidden cursor-pointer",attrs:{"img-url":src,"title":label,"layout":"list"},nativeOn:{"click":function($event){return _vm.onCardClick(id)}}})}):_vm._e()],2),_c('template',{slot:"content"},[(_vm.allCuratedSearches.length && _vm.isAdminView)?[_c('draggable',{ref:"draggableRef",staticClass:"grid gap-6 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-3",attrs:{"force-fallback":_vm.isSafari,"list":_vm.allCuratedSearches,"options":{ animation: 200 },"ghost-class":"opacity-60","element":'div'},on:{"change":_vm.updateCuratedSearchOrder}},_vm._l((_vm.allCuratedSearches),function(curatedSearch,index){return _c('div',{key:("curatedSearchAllSorted-" + index),on:{"mouseover":function($event){_vm.showByIndex = index},"mouseleave":function($event){_vm.showByIndex = null}}},[_c('ui-image-card',{staticClass:"overflow-hidden cursor-move",attrs:{"img-url":curatedSearch.src,"title":curatedSearch.label,"badge":_vm.showBadge(curatedSearch.isCuratedSearchActive),"layout":"list"},nativeOn:{"click":function($event){_vm.onCardClick(curatedSearch.id);
                _vm.useMatomoEvent({
                  category: 'Curated Search',
                  action: 'Click Curated Search',
                  name: ("Curated Search Page (" + (curatedSearch.label) + ")")
                });}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showByIndex === index),expression:"showByIndex === index"}],staticClass:"absolute left-auto z-30 hidden right-2 top-4 md:block"},[_c('a-h-curated-search-context-menu',{staticClass:"rounded-full focus:outline-none",attrs:{"curated-search":curatedSearch},on:{"option-select":_vm.onCuratedSearchSelected}})],1)])],1)}),0)]:_vm._e()],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }