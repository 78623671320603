













































































import {
  computed,
  defineComponent,
  ref,
  inject,
  reactive
} from "@/composition-api";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import UiGridLayout from "@/components/ui/UiGridLayout.vue";
import UiImageCard from "@/components/ui/UiImageCard.vue";
import UiBadge from "@/components/ui/UiBadge.vue";
import CuratedSearchLabel from "@/components/AHCuratedSearch/AHCuratedSearchLabel.vue";
import AHCuratedSearchContextMenu from "./AHCuratedSearchContextMenu.vue";
import useFetchCuratedSearch from "@/composition/useFetchCuratedSearch";
import useGetUserRole from "@/composition/useGetUserRole";
import useUpdateImageIdentifier from "@/composition/useUpdateImageIdentifier";
import useAuthentication from "@/composition/useAuthentication";
import draggable from "vuedraggable";
import store from "@/store";
import useMatomoEvent from "@/composition/useMatomoEvent";
import { isSafari } from "@/composition/useDraggable";

export default defineComponent({
  components: {
    AHCuratedSearchContextMenu,
    CuratedSearchLabel,
    UiBadge,
    UiGridLayout,
    UiImageCard,
    UiTransitionOpacity,
    draggable
  },
  props: {
    filterId: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const eventBus: Vue = inject("eventBus");

    const { validateAndRetrieveToken } = useAuthentication();
    const authJWT = validateAndRetrieveToken();

    const curatedFilterId = computed(() => props.filterId);
    const getSelectedCuratedFilterId = reactive({ curatedFilterId });
    const showByIndex = ref(null);

    const { isAdmin, isEditor, isUser } = useGetUserRole();
    const {
      curatedSearches,
      allCuratedSearches,
      onCardClick,
      setAllCuratedSearches,
      removeCuratedSearch,
      orderCuratedSearches
    } = useFetchCuratedSearch();

    const { handleImageIdentifierDelete } = useUpdateImageIdentifier();

    const isUserView = computed(
      () => isUser.value && !isAdmin.value && !isEditor.value
    );
    const isAdminView = computed(() => isAdmin.value || isEditor.value);

    const showBadge = isPublished => {
      if (isPublished) {
        return "veröffentlicht";
      } else {
        return "entwurf";
      }
    };

    // User view
    const curatedSearchesSorted = computed(() => {
      return orderCuratedSearches(curatedSearches);
    });

    // Admin and editor view

    const onCuratedSearchSelected = ({ value, curatedSearch }) => {
      const authJWT = store.state.auth.token;

      const changeActiveState = computed(() => {
        return curatedSearch.isCuratedSearchActive === true
          ? (curatedSearch.isCuratedSearchActive = false)
          : (curatedSearch.isCuratedSearchActive = true);
      });

      const selectedCuratedFilterId: any = computed(() => {
        return (getSelectedCuratedFilterId.curatedFilterId = curatedSearch.id);
      });

      const curatedFilter = computed(() =>
        store.getters["filters/getFilterById"](selectedCuratedFilterId.value)
      );

      const selectedCuratedSearchImageIdentifier = computed(() => {
        return curatedSearch.imageIdentifier;
      });

      switch (value) {
        case "publish":
          store.dispatch("filters/updateCuratedSearchIsActive", {
            id: curatedSearch.id,
            isCuratedSearchActive: changeActiveState.value,
            authJWT
          });

          showBadge(changeActiveState.value);

          break;
        case "delete":
          // delete Curated Search
          store.dispatch("filters/deleteFilter", {
            filter: curatedFilter.value,
            authJWT
          });

          removeCuratedSearch(curatedSearch);

          // delete imageIdentifier
          handleImageIdentifierDelete(selectedCuratedSearchImageIdentifier);

          eventBus.$emit("show", {
            title: `"${curatedSearch.label}" wurde gelöscht`,
            icon: "checkmark"
          });

          break;
        default:
          break;
      }
    };

    const draggableRef = ref(null);

    const updateCuratedSearchOrder = () => {
      const updatedList = draggableRef.value?.list || [];

      setAllCuratedSearches(updatedList);

      store.dispatch("filters/updateCuratedSearchOrder", {
        curatedSearches: updatedList,
        authJWT
      });
    };

    return {
      curatedSearchesSorted,
      onCardClick,
      isAdmin,
      isEditor,
      isUser,
      showBadge,
      showByIndex,
      isUserView,
      isAdminView,
      onCuratedSearchSelected,
      handleImageIdentifierDelete,
      allCuratedSearches,
      draggableRef,
      updateCuratedSearchOrder,
      useMatomoEvent,
      isSafari
    };
  }
});
