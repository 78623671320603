




import { defineComponent } from "@/composition-api";

export default defineComponent({
  props: {
    label: {
      type: String,
      required: true
    }
  }
});
