<template>
  <div>
    <ui-menu size="medium" :options="options" @optionSelect="handleOptionClick">
      <button
        slot="activator"
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        class="flex items-center justify-center text-white w-8 h-8 hover:bg-gray-800 focus:bg-gray-800  bg-gray-900 bg-opacity-50 focus:outline-none rounded-3xl"
      >
        <ui-icon-more class="w-6 h-6 fill-current" />
      </button>
    </ui-menu>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "@/composition-api";
import UiIconMore from "@/assets/vectors/icons/more.svg?inline";
import UiMenu from "@/components/ui/UiMenu";

export default defineComponent({
  components: { UiIconMore, UiMenu },
  props: {
    curatedSearch: Object
  },
  setup(props, { emit }) {
    const options = ref([
      {
        label: "Veröffentlichen",
        value: "publish"
      },
      {
        label: "Löschen",
        value: "delete"
      }
    ]);

    const curatedSearch = computed(() => props.curatedSearch);

    const handleOptionClick = option => {
      emit("option-select", {
        value: option.value,
        curatedSearch: curatedSearch.value
      });
    };

    return { options, open, close, handleOptionClick };
  }
});
</script>
<style>
.context-menu-options {
  right: -2rem;
}
</style>
